import React, { useEffect } from 'react';
import { Link } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import _ from 'lodash';
import ProductImageCarousel from '../../application/components/product/ProductImageCarousel.component';
import AddToCart from '../../application/components/product/AddToCart.component';
import ProductPrice from '../../application/components/product/ProductPrice.component';
import ProductStock from '../../application/components/product/ProductStock.component';
import './products.css';

const Products = ({ products, clearProducts, addProductToCart, stockSettings, userType, showPrices }) => {
    const { t } = useTranslation();

    const renderProducts = () => {
        if (products) {
            return products.map((p, index) => {
                return (
                    <div className="col-lg-6 col-xl-4" key={`product${index}`}>
                        <div className="product-list__item rounded-box mb-5">
                            <Link to={`/product/${_.get(p, "variants[0]._id")}`} className="product-list__link position-absolute h-100 w-100 zi-1" />
                            <ProductImageCarousel
                                images={p.images}
                            />

                            <div className="p-4 pt-5 position-relative">
                                <p className="h3 product-list-title"
                                    style={{
                                        height: '40px',
                                        overflow: 'hidden'
                                    }}
                                >
                                    {_.get(p, `name[${localStorage.getItem('language')}]`, _.get(p, "name.pl"))}
                                </p>

                                {/* {
                                    (userType !== 'admin' && _.get(p, 'price') > 0) &&
                                    <AddToCart
                                        addProductToCart={addProductToCart}
                                        item={p}
                                        amount={1}
                                    />
                                } */}


                                <span className="product-list__separator"></span>

                                <p className="product-list__desc--primary mt-4"
                                    style={{
                                        height: '20px',
                                        overflow: 'hidden',
                                        whiteSpace: 'nowrap',
                                        textOverflow: 'ellipsis'
                                    }}
                                >
                                    {_.get(p, `description[${localStorage.getItem('language')}]`, _.get(p, "description.pl"))}
                                </p>

                                {
                                    // (_.get(p, 'price', null) || _.get(p, 'discountedPrice', null)) &&
                                    <p className="price__label mt-4">
                                        {(_.get(p, 'discountedPrice') > _.get(p, 'price')) ? t('priceAfterDiscount') : t('price')} {t('from')}
                                    </p>
                                }


                                <ProductPrice
                                    discountedPrice={_.get(p, 'discountedPrice')}
                                    price={_.get(p, 'price')}
                                    currency={_.get(p, 'currency')}
                                    showPrices={showPrices}
                                />

                                {/* <div className="mt-3">
                                    <ProductStock
                                        amount={_.get(p, 'warehouseAmount')}
                                        stockSettings={stockSettings}
                                    />
                                </div> */}
                            </div>
                        </div>
                    </div>
                )
            })
        }
    }
    return (
        <div id="gridView" className="product-list product-list--grid active">
            <div className="row">
                {renderProducts()}
            </div>
        </div>
    )
}

export default Products;
